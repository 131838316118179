import type {
  BundlePromoReferenceType as BundlePromoReference,
  ComponentJsonReferenceType as ComponentJson,
  ExternalLinkReferenceType as ExternalLinkReference,
  ImageReferenceType as ImageReference,
  PromoReferenceType as PromoReference,
  WwwLinkReferenceType as WwwLinkReference,
} from '../../../apps/freeform/types/referenceTypes';
export type {
  PromoReferenceType,
  VariationReferenceType,
} from '../../../apps/freeform/types/referenceTypes';

export enum ProductSubNavType {
  MenuDropDown = 'Menu Drop Down',
  DirectLink = 'Direct Link',
}

type NavLinks = {
  text: string;
  link: WwwLinkReference | ExternalLinkReference;
};

export type AccountProps = {
  displayAccount: boolean;
  title: string;
  links?: NavLinks[];
  authText: {
    signInText: string;
    signOutText: string;
  };
};

export type CartProps = {
  displayCart: boolean;
};

type GeoPickerLinks = {
  text: string;
  link: WwwLinkReference | ExternalLinkReference;
  isUseMyLocationLink?: boolean;
};

export type GeoPickerProps = {
  displayGeoPickerInTopNav: boolean;
  displayGeoPickerInHamburgerNav: boolean;
  eyebrowText: string;
  links?: GeoPickerLinks[];
};

export type ProductDetailsCardProps = {
  key: string;
  image: ImageReference;
  productName: string;
  description: string;
  primaryCTA: {
    text: string;
    link: WwwLinkReference;
  };
  secondaryCTA?: {
    text: string;
    link: WwwLinkReference;
  };
  showHamburgerNavShopButton: boolean;
  hamburgerNavProductCardDirection: 'horizontal' | 'vertical';
  promo?: PromoReference | BundlePromoReference;
};

export type ProductImageProps = {
  image: ImageReference;
};

export type HamburgerMenuProps = {
  displayHamburgerMenu: boolean;
  productSubNavs: ComponentJson<'ProductSubNav (PROSPECTS)'>;
  geoPickerMenu?: ComponentJson<'GeoPicker (PROSPECTS)'>;
};

export type ProductSubNavProps = {
  title: string;
  typeOfProductSubNav: {
    type: 'Menu Drop Down' | 'Direct Link';
    directLink?: WwwLinkReference;
  };
  productDetailCards?: ProductDetailsCardProps[];
  hamburgerSubLinks?: {
    text: string;
    link: WwwLinkReference;
  }[];
  promo?: PromoReference | BundlePromoReference;
};

export type ProductSubNavMenuItemProps = Pick<ProductSubNavProps, 'title'> & {
  isMenuOpen: boolean;
  isActive: boolean;
};

export type ProductSubNavDirectLinkItemProps = Pick<ProductSubNavProps, 'title'> & {
  directLink: WwwLinkReference | ExternalLinkReference;
  textColor: string;
  isActive: boolean;
};

export type DealsSubNavProps = {
  promo?: PromoReference;
  subNavComponent: NavLinks;
};
