import {
  grey,
  spacing,
  Container,
  Flex,
  Eyebrow,
  white,
  Body,
  Icon,
  black,
  Badge,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import type { FC } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { media } from '@peloton/styles';
import { LocationArrow } from '@ecomm/icons';
import { Link } from '@ecomm/internationalize-ui';
import { ProductCard } from '../ProductCard/ProductCard';
import type { GeoPickerProps, ProductSubNavProps } from '../types';

type ProductSubNavItemProps = Pick<
  ProductSubNavProps,
  'title' | 'productDetailCards' | 'hamburgerSubLinks' | 'typeOfProductSubNav' | 'promo'
>;

type HamburgerNavProps = {
  productSubNavs: ProductSubNavProps[];
  geoPickerMenu?: GeoPickerProps;
  onClose?: () => void;
};

export const HamburgerNav: FC<HamburgerNavProps> = ({
  productSubNavs,
  geoPickerMenu,
  onClose,
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
  };

  const handleAnimationEnd = () => {
    if (isClosing) {
      onClose?.();
    }
  };

  return (
    <>
      <Overlay onClick={handleClose} isClosing={isClosing} />
      <NavWrapper
        isClosing={isClosing}
        onAnimationEnd={handleAnimationEnd}
        data-isClosing={isClosing}
      >
        <StyledNavContainer data-test-id="hamburgerNav">
          <CloseButtonContainer data-test-id="closeButton">
            <Icon
              name="close"
              height={24}
              primaryColor={white}
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
              aria-label="Close navigation menu"
            />
          </CloseButtonContainer>
          {productSubNavs.map(productSubNav => (
            <HamburgerNavItem key={productSubNav?.title} {...productSubNav} />
          ))}
          {geoPickerMenu?.displayGeoPickerInHamburgerNav && (
            <GeoPickerNavItem {...geoPickerMenu} />
          )}
        </StyledNavContainer>
      </NavWrapper>
    </>
  );
};

const HamburgerNavItem: FC<ProductSubNavItemProps> = ({
  title,
  productDetailCards,
  hamburgerSubLinks,
  typeOfProductSubNav,
  promo,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    setIsOpen(!isOpen);
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const displayPromoBadgeText = promo?.pillMessaging || '';

  const isDirectLink = typeOfProductSubNav?.type === 'Direct Link';

  const sectionContent = (
    <NavItemContent>
      <Flex flexDirection="row" alignItems="center" gap={spacing[12]}>
        <Eyebrow size={'large'} textColor={white} className="nav-item-eyebrow">
          {title}
        </Eyebrow>
        {displayPromoBadgeText && (
          <StyledBadge variant="horizontal" theme="accent" data-test-id="promo-badge">
            {displayPromoBadgeText}
          </StyledBadge>
        )}
      </Flex>
      {!isDirectLink && (
        <StyledChevron
          name="chevron"
          primaryColor={grey[50]}
          aria-hidden={true}
          height={12}
          rotate={isOpen ? 90 : 270}
          data-test-id="nav-item-chevron"
        />
      )}
    </NavItemContent>
  );

  return (
    <StyledNavItem
      onClick={!isDirectLink ? handleClick : undefined}
      data-test-id="nav-item"
    >
      {isDirectLink ? (
        <Link href={typeOfProductSubNav.directLink?.url} hasUnderline={false}>
          {sectionContent}
        </Link>
      ) : (
        sectionContent
      )}
      {!isDirectLink && (isOpen || isAnimating) && (
        <DropdownContent
          data-test-id="dropdown-content"
          onAnimationEnd={handleAnimationEnd}
          isOpen={isOpen}
        >
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-end"
            gap={{ mobile: spacing[16], desktop: spacing[24] }}
          >
            {productDetailCards?.map(productCard => (
              <ProductCard
                key={productCard.key}
                productCard={productCard}
                direction={productCard.hamburgerNavProductCardDirection}
                showShopButton={productCard.showHamburgerNavShopButton}
              />
            ))}
            <Flex flexDirection="column" gap={spacing[16]}>
              {hamburgerSubLinks?.map(link => (
                <StyledLink key={link.text} href={link.link.url} hasUnderline={false}>
                  <Body size="small" textColor={grey[30]}>
                    {link.text}
                  </Body>
                </StyledLink>
              ))}
            </Flex>
          </Flex>
        </DropdownContent>
      )}
    </StyledNavItem>
  );
};

const GeoPickerNavItem: FC<GeoPickerProps> = ({ eyebrowText, links }) => {
  return (
    <StyledNavItem isGeoPicker={true} padding={`${spacing[24]} 0`}>
      <Flex flexDirection="column" gap={spacing[16]}>
        <Eyebrow size={'medium'} textColor={white}>
          {eyebrowText}
        </Eyebrow>
        {links?.map(link => (
          <StyledLink key={link?.text} href={link?.link?.url} hasUnderline={false}>
            <Flex flexDirection="row" gap={spacing[8]} alignItems="center">
              <Body size="small" textColor={grey[30]}>
                {link?.text}
              </Body>
              {link?.isUseMyLocationLink && (
                <LocationArrow title="Geolocation arrow" color={grey[30]} />
              )}
            </Flex>
          </StyledLink>
        ))}
      </Flex>
    </StyledNavItem>
  );
};

const ANIMATION_CONFIG = {
  duration: '0.5s',
  easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
} as const;

const slideIn = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;
const slideOut = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
`;

const rollOut = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
`;

const rollIn = keyframes`
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
  }
`;

const NavWrapper = styled.div<{ isClosing: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  animation: ${props =>
    props.isClosing
      ? css`
          ${slideOut} ${ANIMATION_CONFIG.duration} ${ANIMATION_CONFIG.easing} forwards
        `
      : css`
          ${slideIn} ${ANIMATION_CONFIG.duration} ${ANIMATION_CONFIG.easing} forwards
        `};
`;

const Overlay = styled.button<{ isClosing: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  border: none;
  padding: 0;
  margin: 0;
  background-color: ${black};
  opacity: ${props => (props.isClosing ? 0 : 0.2)};
  transition: opacity ${ANIMATION_CONFIG.duration} ${ANIMATION_CONFIG.easing};
`;

const StyledNavContainer = styled(Container)`
  background-color: ${grey[90]};
  display: flex;
  flex-direction: column;
  padding: ${spacing[16]};
  height: 100vh;
  width: 100vw;
  ${media.tablet`
    width: 486px;
  `}
  ${media.desktopLarge`
    width: 572px;
    padding: ${spacing[24]};
  `}
  overflow-y: auto;
`;

const StyledChevron = styled(Icon)`
  margin-right: ${spacing[8]};
  transition: all 0.4s ease-in-out 0s;
`;

const StyledNavItem = styled(Flex)<{ isGeoPicker?: boolean }>`
  width: 100%;
  padding-bottom: 0;
  cursor: ${props => (props.isGeoPicker ? 'default' : 'pointer')};
  border-bottom: ${props => (props.isGeoPicker ? 'none' : `1px solid ${grey[70]}`)};
  flex-direction: column;

  &:hover {
    .nav-item-eyebrow {
      color: ${grey[50]};
    }
  }
`;

const NavItemContent = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing[32]} 0;
`;

const DropdownContent = styled(Flex)<{ isOpen: boolean }>`
  width: 100%;
  background-color: ${grey[90]};
  margin-bottom: ${spacing[24]};
  overflow: hidden;
  animation: ${props =>
    props.isOpen
      ? css`
          ${rollOut} 0.3s ease-out forwards
        `
      : css`
          ${rollIn} 0.3s ease-out forwards
        `};
  max-height: 0;
`;

const CloseButtonContainer = styled(Flex)`
  padding-bottom: ${spacing[8]};
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  &:hover {
    p {
      color: ${grey[50]};
    }
  }
`;

const StyledBadge = styled(Badge)`
  font-size: 13px !important;
  border-radius: ${spacing[4]}px;
  padding: ${spacing[4]}px ${spacing[8]}px;
  max-height: ${spacing[24]};
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default HamburgerNav;
